import { HttpInterceptorFn } from '@angular/common/http';
import { catchError } from 'rxjs/operators';

export const authInterceptor: HttpInterceptorFn = (req, next) => {
    return next(req).pipe(
        catchError((error) => {
            if (error.status === 401 || error.status === 403) {
                window.location.href = "auth"
            }
            throw error;
        })
    );
};
